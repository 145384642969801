import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Line, Doughnut, Bar, Radar } from 'react-chartjs-2'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Box from '../components/Box'
import PropertyLabel from '../components/PropertyLabel'
import ErrorMessage from '../components/ErrorMessage'

import { fetchArqueos } from '../actions/arqueos'
import ArqueosSalesFilterForm from '../forms/ArqueosSalesFilterForm'


class ArqueosSalesView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showForm: false,
      selectedType: 'Units',
    }

    this.handleTypeClick = this.handleTypeClick.bind(this);
  }

  componentDidMount() {
    const today = new Date();
    const currentYear = today.getFullYear();
    const yearStart = new Date(currentYear, 0, 1);
    const formattedStartDate =
      `${yearStart.getFullYear()}-${(yearStart.getMonth() + 1).toString().padStart(2, '0')}-${yearStart.getDate().toString().padStart(2, '0')}`;
    const yearEnd = new Date(currentYear + 1, 0, 0);
    const formattedEndDate =
      `${yearEnd.getFullYear()}-${(yearEnd.getMonth() + 1).toString().padStart(2, '0')}-${yearEnd.getDate().toString().padStart(2, '0')}`;

    this.props.fetchArqueos('', '', formattedStartDate, formattedEndDate, '');
  }

  calculateUnitsCustomerTotals(customer) {
    const { arqueos } = this.props;

    // Initialize an object to store monthly totals
    const monthlyTotals = {
      VWPACK: Array(12).fill(0),
      VWGO: Array(12).fill(0),
      VWLAY: Array(12).fill(0),
      VWPACKManual: Array(12).fill(0),
      VWGOManual: Array(12).fill(0),
      VWLAYManual: Array(12).fill(0),
      productMonthTotal: Array(12).fill(0),
      productTotal: 0,
      ticketsMonthTotal: Array(12).fill(0),
      ticketsTotal: 0,
      VWPACKTotal: 0,
      VWGOTotal: 0,
      VWLAYTotal: 0,
      VWPACKManualTotal: 0,
      VWGOManualTotal: 0,
      VWLAYManualTotal: 0,
    };

    // Iterate through arqueos to find the ones belonging to the current customer
    arqueos.forEach(arqueo => {
      if (arqueo.Customer && arqueo.Customer.Fullname === customer && arqueo.Customer._id !== "627e3b97db8dc91eb9cc3ac2") {

        // Extract month from the timestamp
        const month = new Date(arqueo.TimeStamp).getUTCMonth();

        // Accumulate monthly totals
        monthlyTotals.VWPACK[month] += arqueo.Items.VWPACK || 0;
        monthlyTotals.VWGO[month] += arqueo.Items.VWGO || 0;
        monthlyTotals.VWLAY[month] += arqueo.Items.VWLAY || 0;
        monthlyTotals.VWPACKManual[month] += arqueo.Items.VWPACKManual || 0;
        monthlyTotals.VWGOManual[month] += arqueo.Items.VWGOManual || 0;
        monthlyTotals.VWLAYManual[month] += arqueo.Items.VWLAYManual || 0;
        monthlyTotals.productMonthTotal[month] += (arqueo.Items.VWPACK || 0) + (arqueo.Items.VWLAY || 0) + (arqueo.Items.VWGO || 0) + (arqueo.Items.VWPACKManual || 0) + (arqueo.Items.VWLAYManual || 0) + (arqueo.Items.VWGOManual || 0);
        monthlyTotals.productTotal += (arqueo.Items.VWPACK || 0) + (arqueo.Items.VWLAY || 0) + (arqueo.Items.VWGO || 0) + (arqueo.Items.VWPACKManual || 0) + (arqueo.Items.VWLAYManual || 0) + (arqueo.Items.VWGOManual || 0);
        monthlyTotals.ticketsMonthTotal[month] += arqueo.TotalTickets
        monthlyTotals.ticketsTotal += arqueo.TotalTickets
        monthlyTotals.VWPACKTotal += arqueo.Items.VWPACK || 0;
        monthlyTotals.VWGOTotal += arqueo.Items.VWGO || 0;
        monthlyTotals.VWLAYTotal += arqueo.Items.VWLAY || 0;
        monthlyTotals.VWPACKManualTotal += arqueo.Items.VWPACKManual || 0;
        monthlyTotals.VWGOManualTotal += arqueo.Items.VWGOManual || 0;
        monthlyTotals.VWLAYManualTotal += arqueo.Items.VWLAYManual || 0;
      }
    });

    return {
      customerName: customer,
      monthlyTotals,
    };
  }


  createUnitsClientsArray() {
    const { arqueos } = this.props;

    if (!arqueos) {
      return (
        <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      );
    }

    const customers = Array.from(new Set(arqueos.map(arqueo => arqueo.Customer && arqueo.Customer.Fullname)));
    const clientsArray = [];

    customers.forEach(customer => {
      if (customer && customer !== "VACWAY WATERPROOF SL") {
        const customerTotals = this.calculateUnitsCustomerTotals(customer);
        clientsArray.push(customerTotals);
      }
    });

    // Sort clientsArray in descending order based on productTotal
    clientsArray.sort((a, b) => b.monthlyTotals.productTotal - a.monthlyTotals.productTotal);

    return clientsArray;
  }

  renderUnitsTableBody() {
    const { arqueos, loading, error } = this.props;
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueos || loading) {
      return <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>;
    }

    const clientsArray = this.createUnitsClientsArray();

    const totalRow = {
      customerName: 'Todos',
      monthlyTotals: {
        productTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.productTotal, 0),
        ticketsTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.ticketsTotal, 0),
        VWPACKTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.VWPACKTotal, 0),
        VWLAYTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.VWLAYTotal, 0),
        VWGOTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.VWGOTotal, 0),
        VWPACKManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.VWPACKManualTotal, 0),
        VWLAYManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.VWLAYManualTotal, 0),
        VWGOManualTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.VWGOManualTotal, 0),
        productMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.productMonthTotal[i]), Array(12).fill(0)),
        ticketsMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.ticketsMonthTotal[i]), Array(12).fill(0)),
        VWPACKMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.VWPACK[i]), Array(12).fill(0)),
        VWLAYMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.VWLAY[i]), Array(12).fill(0)),
        VWGOMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.VWGO[i]), Array(12).fill(0)),
        VWPACKManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.VWPACKManual[i]), Array(12).fill(0)),
        VWLAYManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.VWLAYManual[i]), Array(12).fill(0)),
        VWGOManualMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.VWGOManual[i]), Array(12).fill(0)),
      },
    };

    return (
      <tbody>
        <tr className="thead-totals">
          <th scope="row">{totalRow.customerName}</th>
          <th>{`${(totalRow.monthlyTotals.productTotal).toLocaleString("es-ES")} u`}</th>
          {totalRow.monthlyTotals.productMonthTotal.map((total, monthIndex) => (
            <td key={monthIndex}>{`${(total).toLocaleString("es-ES")} u`}</td>
          ))}
        </tr>
        <tr>
          <th scope="row">VWPACK</th>
          <th>{`${(totalRow.monthlyTotals.VWPACKTotal).toLocaleString("es-ES")} u`}</th>
          {totalRow.monthlyTotals.VWPACKMonthTotal.map((total, monthIndex) => (
            <td key={monthIndex}>{`${(total).toLocaleString("es-ES")} u`}</td>
          ))}
        </tr>
        <tr>
          <th scope="row">VWLAY</th>
          <th>{`${(totalRow.monthlyTotals.VWLAYTotal).toLocaleString("es-ES")} u`}</th>
          {totalRow.monthlyTotals.VWLAYMonthTotal.map((total, monthIndex) => (
            <td key={monthIndex}>{`${(total).toLocaleString("es-ES")} u`}</td>
          ))}
        </tr>
        <tr>
          <th scope="row">VWGO</th>
          <th>{`${(totalRow.monthlyTotals.VWGOTotal).toLocaleString("es-ES")} u`}</th>
          {totalRow.monthlyTotals.VWGOMonthTotal.map((total, monthIndex) => (
            <td key={monthIndex}>{`${(total).toLocaleString("es-ES")} u`}</td>
          ))}
        </tr>
        <tr className="thead-manual">
          <th scope="row">VWPACK Manual</th>
          <th>{`${(totalRow.monthlyTotals.VWPACKManualTotal).toLocaleString("es-ES")} u`}</th>
          {totalRow.monthlyTotals.VWPACKManualMonthTotal.map((total, monthIndex) => (
            <td key={monthIndex}>{`${(total).toLocaleString("es-ES")} u`}</td>
          ))}
        </tr>
        <tr className="thead-manual">
          <th scope="row">VWLAY Manual</th>
          <th>{`${(totalRow.monthlyTotals.VWLAYManualTotal).toLocaleString("es-ES")} u`}</th>
          {totalRow.monthlyTotals.VWLAYManualMonthTotal.map((total, monthIndex) => (
            <td key={monthIndex}>{`${(total).toLocaleString("es-ES")} u`}</td>
          ))}
        </tr>
        <tr className="thead-manual">
          <th scope="row">VWGO Manual</th>
          <th>{`${(totalRow.monthlyTotals.VWGOManualTotal).toLocaleString("es-ES")} u`}</th>
          {totalRow.monthlyTotals.VWGOManualMonthTotal.map((total, monthIndex) => (
            <td key={monthIndex}>{`${(total).toLocaleString("es-ES")} u`}</td>
          ))}
        </tr>
        <tr className="thead-clients">
          <th scope="row">Clientes</th>
          <th>{`${(totalRow.monthlyTotals.ticketsTotal).toLocaleString("es-ES")}`}</th>
          {totalRow.monthlyTotals.ticketsMonthTotal.map((ticketsTotal, monthIndex) => {
            return <td key={monthIndex}>{`${(ticketsTotal).toLocaleString("es-ES")}`}</td>;
          })}
        </tr>
        <tr className="thead-clients">
          <th scope="row">KPI Lay</th>
          <th>{((totalRow.monthlyTotals.VWLAYTotal / totalRow.monthlyTotals.VWPACKTotal) * 100).toFixed(2)}%</th>
          {totalRow.monthlyTotals.VWPACKMonthTotal.map((total, monthIndex) => (
            <td key={monthIndex}>{((totalRow.monthlyTotals.VWLAYMonthTotal[monthIndex] / total) * 100).toFixed(2)}%</td>
          ))}
        </tr>
        <tr className="thead-clients">
          <th scope="row">KPI Go</th>
          <th>{((totalRow.monthlyTotals.VWGOTotal / totalRow.monthlyTotals.VWPACKTotal) * 100).toFixed(2)}%</th>
          {totalRow.monthlyTotals.VWPACKMonthTotal.map((total, monthIndex) => (
            <td key={monthIndex}>{((totalRow.monthlyTotals.VWGOMonthTotal[monthIndex] / total) * 100).toFixed(2)}%</td>
          ))}
        </tr>
        <tr className="thead-clients">
          <th scope="row">Venta Cruzada</th>
          <th>{(((totalRow.monthlyTotals.VWLAYTotal + totalRow.monthlyTotals.VWGOTotal) / totalRow.monthlyTotals.VWPACKTotal) * 100).toFixed(2)}%</th>
          {totalRow.monthlyTotals.VWPACKMonthTotal.map((total, monthIndex) => (
            <td key={monthIndex}>{(((totalRow.monthlyTotals.VWLAYMonthTotal[monthIndex] + totalRow.monthlyTotals.VWGOMonthTotal[monthIndex]) / total) * 100).toFixed(2)}%</td>
          ))}
        </tr>
        {clientsArray.map((client, index) => (
          <React.Fragment key={index}>
            <tr className="thead-dark">
              <th scope="row">{client.customerName}</th>
              <th>{`${(client.monthlyTotals.productTotal).toLocaleString("es-ES")} u`}</th>
              {client.monthlyTotals.productMonthTotal.map((total, monthIndex) => (
                <th key={monthIndex}>{`${(total).toLocaleString("es-ES")} u`}</th>
              ))}
            </tr>
            <tr>
              <td scope="row">VWPACK</td>
              <td>{`${(client.monthlyTotals.VWPACK.reduce((sum, value) => sum + value, 0)).toLocaleString("es-ES")} u`}</td>
              {client.monthlyTotals.VWPACK.map((total, monthIndex) => (
                <td key={monthIndex}>{`${(total).toLocaleString("es-ES")} u`}</td>
              ))}
            </tr>
            <tr>
              <td scope="row">VWLAY</td>
              <td>{`${(client.monthlyTotals.VWLAY.reduce((sum, value) => sum + value, 0)).toLocaleString("es-ES")} u`}</td>
              {client.monthlyTotals.VWLAY.map((total, monthIndex) => (
                <td key={monthIndex}>{`${(total).toLocaleString("es-ES")} u`}</td>
              ))}
            </tr>
            <tr>
              <td scope="row">VWGO</td>
              <td>{`${(client.monthlyTotals.VWGO.reduce((sum, value) => sum + value, 0)).toLocaleString("es-ES")} u`}</td>
              {client.monthlyTotals.VWGO.map((total, monthIndex) => (
                <td key={monthIndex}>{`${(total).toLocaleString("es-ES")} u`}</td>
              ))}
            </tr>
            <tr className="thead-manual">
              <td scope="row">VWPACK Manual</td>
              <td>{`${(client.monthlyTotals.VWPACKManual.reduce((sum, value) => sum + value, 0)).toLocaleString("es-ES")} u`}</td>
              {client.monthlyTotals.VWPACKManual.map((total, monthIndex) => (
                <td key={monthIndex}>{`${(total).toLocaleString("es-ES")} u`}</td>
              ))}
            </tr>
            <tr className="thead-manual">
              <td scope="row">VWLAY Manual</td>
              <td>{`${(client.monthlyTotals.VWLAYManual.reduce((sum, value) => sum + value, 0)).toLocaleString("es-ES")} u`}</td>
              {client.monthlyTotals.VWLAYManual.map((total, monthIndex) => (
                <td key={monthIndex}>{`${(total).toLocaleString("es-ES")} u`}</td>
              ))}
            </tr>
            <tr className="thead-manual">
              <td scope="row">VWGO Manual</td>
              <td>{`${(client.monthlyTotals.VWGOManual.reduce((sum, value) => sum + value, 0)).toLocaleString("es-ES")} u`}</td>
              {client.monthlyTotals.VWGOManual.map((total, monthIndex) => (
                <td key={monthIndex}>{`${(total).toLocaleString("es-ES")} u`}</td>
              ))}
            </tr>
            <tr className="thead-clients">
              <th scope="row">Clientes</th>
              <th>{`${(client.monthlyTotals.ticketsTotal).toLocaleString("es-ES")}`}</th>
              {client.monthlyTotals.ticketsMonthTotal.map((ticketsTotal, monthIndex) => {
                return <td key={monthIndex}>{`${(ticketsTotal).toLocaleString("es-ES")}`}</td>;
              })}
            </tr>
            <tr className="thead-clients">
              <th scope="row">KPI Lay</th>
              <th>{((client.monthlyTotals.VWLAY.reduce((sum, value) => sum + value, 0) / client.monthlyTotals.VWPACK.reduce((sum, value) => sum + value, 0)) * 100).toFixed(2)}%</th>
              {client.monthlyTotals.VWPACK.map((total, monthIndex) => (
                <td key={monthIndex}>{((client.monthlyTotals.VWLAY[monthIndex] / total) * 100).toFixed(2)}%</td>
              ))}
            </tr>
            <tr className="thead-clients">
              <th scope="row">KPI Go</th>
              <th>{((client.monthlyTotals.VWGO.reduce((sum, value) => sum + value, 0) / client.monthlyTotals.VWPACK.reduce((sum, value) => sum + value, 0)) * 100).toFixed(2)}%</th>
              {client.monthlyTotals.VWPACK.map((total, monthIndex) => (
                <td key={monthIndex}>{((client.monthlyTotals.VWGO[monthIndex] / total) * 100).toFixed(2)}%</td>
              ))}
            </tr>
            <tr className="thead-clients">
              <th scope="row">Venta Cruzada</th>
              <th>{(((client.monthlyTotals.VWLAY.reduce((sum, value) => sum + value, 0) + client.monthlyTotals.VWGO.reduce((sum, value) => sum + value, 0)) / client.monthlyTotals.VWPACK.reduce((sum, value) => sum + value, 0)) * 100).toFixed(2)}%</th>
              {client.monthlyTotals.VWPACK.map((total, monthIndex) => (
                <td key={monthIndex}>{(((client.monthlyTotals.VWLAY[monthIndex] + client.monthlyTotals.VWGO[monthIndex]) / total) * 100).toFixed(2)}%</td>
              ))}
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    );
  }

  calculatePriceWithoutTax(priceWithTax, taxRate) {
    // Convert tax rate from percentage to decimal
    const taxMultiplier = 1 + taxRate / 100;

    // Calculate price without tax and round to two decimal places
    const priceWithoutTax = (priceWithTax / taxMultiplier).toFixed(2);

    // Convert the string back to a number
    return parseFloat(priceWithoutTax);
  }

  calculateGrossCustomerTotals(customer) {
    const { arqueos } = this.props;

    // Initialize an object to store monthly totals
    const monthlyTotals = {
      VWPACK: Array(12).fill(0),
      VWGO: Array(12).fill(0),
      VWLAY: Array(12).fill(0),
      productMonthTotal: Array(12).fill(0),
      productTotal: 0,
      ticketsMonthTotal: Array(12).fill(0),
      ticketsTotal: 0,
      VWPACKTotal: 0,
      VWGOTotal: 0,
      VWLAYTotal: 0,
    };

    // Iterate through arqueos to find the ones belonging to the current customer
    arqueos.forEach(arqueo => {
      if (arqueo.Customer && arqueo.Customer.Fullname === customer && arqueo.Customer._id !== "627e3b97db8dc91eb9cc3ac2") {
        // Extract month from the timestamp
        const month = new Date(arqueo.TimeStamp).getUTCMonth();

        // Accumulate monthly totals
        monthlyTotals.VWPACK[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.VWPACK, arqueo.Tax) || 0;
        monthlyTotals.VWGO[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.VWGO, arqueo.Tax) || 0;
        monthlyTotals.VWLAY[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.VWLAY, arqueo.Tax) || 0;
        monthlyTotals.productMonthTotal[month] += (this.calculatePriceWithoutTax(arqueo.ItemsPrices.VWPACK, arqueo.Tax) || 0) + (this.calculatePriceWithoutTax(arqueo.ItemsPrices.VWGO, arqueo.Tax) || 0) + (this.calculatePriceWithoutTax(arqueo.ItemsPrices.VWLAY, arqueo.Tax) || 0);
        monthlyTotals.productTotal += (this.calculatePriceWithoutTax(arqueo.ItemsPrices.VWPACK, arqueo.Tax) || 0) + (this.calculatePriceWithoutTax(arqueo.ItemsPrices.VWGO, arqueo.Tax) || 0) + (this.calculatePriceWithoutTax(arqueo.ItemsPrices.VWLAY, arqueo.Tax) || 0);
        monthlyTotals.ticketsMonthTotal[month] += arqueo.TotalTickets
        monthlyTotals.ticketsTotal += arqueo.TotalTickets
        monthlyTotals.VWPACKTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.VWPACK, arqueo.Tax) || 0;
        monthlyTotals.VWGOTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.VWGO, arqueo.Tax) || 0;
        monthlyTotals.VWLAYTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.VWLAY, arqueo.Tax) || 0;
      }
    });

    return {
      customerName: customer,
      monthlyTotals,
    };
  }


  createGrossClientsArray() {
    const { arqueos } = this.props;

    if (!arqueos) {
      return (
        <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      );
    }

    const customers = Array.from(new Set(arqueos.map(arqueo => arqueo.Customer && arqueo.Customer.Fullname)));
    const clientsArray = [];

    customers.forEach(customer => {
      if (customer && customer !== "VACWAY WATERPROOF SL") {
        const customerTotals = this.calculateGrossCustomerTotals(customer);
        clientsArray.push(customerTotals);
      }
    });

    // Sort clientsArray in descending order based on productTotal
    clientsArray.sort((a, b) => b.monthlyTotals.productTotal - a.monthlyTotals.productTotal);

    return clientsArray;
  }

  renderGrossTableBody() {
    const { arqueos, loading, error } = this.props;
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueos || loading) {
      return <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>;
    }

    const clientsArray = this.createGrossClientsArray();

    const totalRow = {
      customerName: 'Todos',
      monthlyTotals: {
        productTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.productTotal, 0),
        ticketsTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.ticketsTotal, 0),
        VWPACKTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.VWPACKTotal, 0),
        VWLAYTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.VWLAYTotal, 0),
        VWGOTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.VWGOTotal, 0),
        productMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.productMonthTotal[i]), Array(12).fill(0)),
        ticketsMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.ticketsMonthTotal[i]), Array(12).fill(0)),
        VWPACKMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.VWPACK[i]), Array(12).fill(0)),
        VWLAYMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.VWLAY[i]), Array(12).fill(0)),
        VWGOMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.VWGO[i]), Array(12).fill(0)),
      },
    };

    return (
      <tbody>
        <tr className="thead-totals">
          <th scope="row">Todos (GMV)</th>
          <th>{`${(totalRow.monthlyTotals.productTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
          {totalRow.monthlyTotals.productMonthTotal.map((total, monthIndex) => (
            <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
          ))}
        </tr>
        <tr>
          <th scope="row">VWPACK</th>
          <th>{`${(totalRow.monthlyTotals.VWPACKTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
          {totalRow.monthlyTotals.VWPACKMonthTotal.map((total, monthIndex) => (
            <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
          ))}
        </tr>
        <tr>
          <th scope="row">VWLAY</th>
          <th>{`${(totalRow.monthlyTotals.VWLAYTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
          {totalRow.monthlyTotals.VWLAYMonthTotal.map((total, monthIndex) => (
            <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
          ))}
        </tr>
        <tr>
          <th scope="row">VWGO</th>
          <th>{`${(totalRow.monthlyTotals.VWGOTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
          {totalRow.monthlyTotals.VWGOMonthTotal.map((total, monthIndex) => (
            <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
          ))}
        </tr>
        <tr className="thead-clients">
          <th scope="row">Ticket Medio</th>
          <th>{`${(totalRow.monthlyTotals.productTotal / totalRow.monthlyTotals.ticketsTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
          {totalRow.monthlyTotals.productMonthTotal.map((productTotal, monthIndex) => {
            const ticketsTotal = totalRow.monthlyTotals.ticketsMonthTotal[monthIndex];
            const averageTicket = ticketsTotal !== 0 ? `${(productTotal / ticketsTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}` : "N/A";

            return <td key={monthIndex}>{`${(averageTicket).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>;
          })}
        </tr>
        <tr className="thead-clients">
          <th scope="row">Clientes</th>
          <th>{totalRow.monthlyTotals.ticketsTotal}</th>
          {totalRow.monthlyTotals.ticketsMonthTotal.map((ticketsTotal, monthIndex) => {
            return <td key={monthIndex}>{`${(ticketsTotal).toLocaleString("es-ES")}`}</td>;
          })}
        </tr>
        {clientsArray.map((client, index) => (
          <React.Fragment key={index}>
            <tr className="thead-dark">
              <th scope="row">{client.customerName}</th>
              <th>{`${(client.monthlyTotals.productTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
              {client.monthlyTotals.productMonthTotal.map((total, monthIndex) => (
                <th key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
              ))}
            </tr>
            <tr>
              <td scope="row">VWPACK</td>
              <td>{`${(client.monthlyTotals.VWPACK.reduce((sum, value) => sum + value, 0)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
              {client.monthlyTotals.VWPACK.map((total, monthIndex) => (
                <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
              ))}
            </tr>
            <tr>
              <td scope="row">VWLAY</td>
              <td>{`${(client.monthlyTotals.VWLAY.reduce((sum, value) => sum + value, 0)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
              {client.monthlyTotals.VWLAY.map((total, monthIndex) => (
                <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
              ))}
            </tr>
            <tr>
              <td scope="row">VWGO</td>
              <td>{`${(client.monthlyTotals.VWGO.reduce((sum, value) => sum + value, 0)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
              {client.monthlyTotals.VWGO.map((total, monthIndex) => (
                <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
              ))}
            </tr>
            <tr className="thead-clients">
              <th scope="row">Ticket Medio</th>
              <th>{`${(client.monthlyTotals.productTotal / client.monthlyTotals.ticketsTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
              {client.monthlyTotals.productMonthTotal.map((productTotal, monthIndex) => {
                const ticketsTotal = client.monthlyTotals.ticketsMonthTotal[monthIndex];
                const averageTicket = ticketsTotal !== 0 ? `${(productTotal / ticketsTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}` : "N/A";

                return <td key={monthIndex}>{`${(averageTicket).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>;
              })}
            </tr>
            <tr className="thead-clients">
              <th scope="row">Clientes</th>
              <th>{client.monthlyTotals.ticketsTotal}</th>
              {client.monthlyTotals.ticketsMonthTotal.map((ticketsTotal, monthIndex) => {
                return <td key={monthIndex}>{`${(ticketsTotal).toLocaleString("es-ES")}`}</td>;
              })}
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    );
  }

  calculateActiveCustomerTotals(customer) {
    const { arqueos } = this.props;

    // Initialize an object to store monthly totals
    const monthlyTotals = {
      VWPACK: Array(12).fill(0),
      VWGO: Array(12).fill(0),
      VWLAY: Array(12).fill(0),
      productMonthTotal: Array(12).fill(0),
      productTotal: 0,
      ticketsMonthTotal: Array(12).fill(0),
      ticketsTotal: 0,
      VWPACKTotal: 0,
      VWGOTotal: 0,
      VWLAYTotal: 0,
    };

    // Iterate through arqueos to find the ones belonging to the current customer
    arqueos.forEach(arqueo => {
      if (arqueo.Customer && arqueo.Customer.Fullname === customer && arqueo.Customer._id !== "627e3b97db8dc91eb9cc3ac2") {
        // Extract month from the timestamp
        const month = new Date(arqueo.TimeStamp).getUTCMonth();

        // Accumulate monthly totals
        monthlyTotals.VWPACK[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.VWPACK, arqueo.Tax) || 0;
        monthlyTotals.VWGO[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.VWGO, arqueo.Tax) || 0;
        monthlyTotals.VWLAY[month] += this.calculatePriceWithoutTax(arqueo.ItemsPrices.VWLAY, arqueo.Tax) || 0;
        monthlyTotals.productMonthTotal[month] += (this.calculatePriceWithoutTax(arqueo.ItemsPrices.VWPACK, arqueo.Tax) || 0) + (this.calculatePriceWithoutTax(arqueo.ItemsPrices.VWGO, arqueo.Tax) || 0) + (this.calculatePriceWithoutTax(arqueo.ItemsPrices.VWLAY, arqueo.Tax) || 0);
        monthlyTotals.productTotal += (this.calculatePriceWithoutTax(arqueo.ItemsPrices.VWPACK, arqueo.Tax) || 0) + (this.calculatePriceWithoutTax(arqueo.ItemsPrices.VWGO, arqueo.Tax) || 0) + (this.calculatePriceWithoutTax(arqueo.ItemsPrices.VWLAY, arqueo.Tax) || 0);
        monthlyTotals.ticketsMonthTotal[month] += arqueo.TotalTickets
        monthlyTotals.ticketsTotal += arqueo.TotalTickets
        monthlyTotals.VWPACKTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.VWPACK, arqueo.Tax) || 0;
        monthlyTotals.VWGOTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.VWGO, arqueo.Tax) || 0;
        monthlyTotals.VWLAYTotal += this.calculatePriceWithoutTax(arqueo.ItemsPrices.VWLAY, arqueo.Tax) || 0;
      }
    });

    return {
      customerName: customer,
      monthlyTotals,
    };
  }

  createActiveClientsArray() {
    const { arqueos } = this.props;

    if (!arqueos) {
      return (
        <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      );
    }

    const customers = Array.from(new Set(arqueos.map(arqueo => (arqueo.Customer && arqueo.ContractType != "SALE") && arqueo.Customer.Fullname)));
    const clientsArray = [];

    customers.forEach(customer => {
      if (customer && customer !== "VACWAY WATERPROOF SL") {
        const customerTotals = this.calculateActiveCustomerTotals(customer);
        clientsArray.push(customerTotals);
      }
    });

    // Sort clientsArray in descending order based on productTotal
    clientsArray.sort((a, b) => b.monthlyTotals.productTotal - a.monthlyTotals.productTotal);

    return clientsArray;
  }

  renderActiveTableBody() {
    const { arqueos, loading, error } = this.props;
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueos || loading) {
      return <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>;
    }

    const clientsArray = this.createActiveClientsArray();

    const totalRow = {
      customerName: 'Todos',
      monthlyTotals: {
        productTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.productTotal, 0),
        ticketsTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.ticketsTotal, 0),
        VWPACKTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.VWPACKTotal, 0),
        VWLAYTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.VWLAYTotal, 0),
        VWGOTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.VWGOTotal, 0),
        productMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.productMonthTotal[i]), Array(12).fill(0)),
        ticketsMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.ticketsMonthTotal[i]), Array(12).fill(0)),
        VWPACKMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.VWPACK[i]), Array(12).fill(0)),
        VWLAYMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.VWLAY[i]), Array(12).fill(0)),
        VWGOMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.VWGO[i]), Array(12).fill(0)),
      },
    };

    return (
      <tbody>
        <tr className="thead-totals">
          <th scope="row">Todos (GMV)</th>
          <th>{`${(totalRow.monthlyTotals.productTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
          {totalRow.monthlyTotals.productMonthTotal.map((total, monthIndex) => (
            <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
          ))}
        </tr>
        <tr>
          <th scope="row">VWPACK</th>
          <th>{`${(totalRow.monthlyTotals.VWPACKTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
          {totalRow.monthlyTotals.VWPACKMonthTotal.map((total, monthIndex) => (
            <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
          ))}
        </tr>
        <tr>
          <th scope="row">VWLAY</th>
          <th>{`${(totalRow.monthlyTotals.VWLAYTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
          {totalRow.monthlyTotals.VWLAYMonthTotal.map((total, monthIndex) => (
            <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
          ))}
        </tr>
        <tr>
          <th scope="row">VWGO</th>
          <th>{`${(totalRow.monthlyTotals.VWGOTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
          {totalRow.monthlyTotals.VWGOMonthTotal.map((total, monthIndex) => (
            <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
          ))}
        </tr>
        <tr className="thead-clients">
          <th scope="row">Ticket Medio</th>
          <th>{`${(totalRow.monthlyTotals.productTotal / totalRow.monthlyTotals.ticketsTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
          {totalRow.monthlyTotals.productMonthTotal.map((productTotal, monthIndex) => {
            const ticketsTotal = totalRow.monthlyTotals.ticketsMonthTotal[monthIndex];
            const averageTicket = ticketsTotal !== 0 ? `${(productTotal / ticketsTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}` : "N/A";

            return <td key={monthIndex}>{`${(averageTicket).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>;
          })}
        </tr>
        <tr className="thead-clients">
          <th scope="row">Clientes</th>
          <th>{totalRow.monthlyTotals.ticketsTotal}</th>
          {totalRow.monthlyTotals.ticketsMonthTotal.map((ticketsTotal, monthIndex) => {
            return <td key={monthIndex}>{`${(ticketsTotal).toLocaleString("es-ES")}`}</td>;
          })}
        </tr>
        {clientsArray.map((client, index) => (
          <React.Fragment key={index}>
            <tr className="thead-dark">
              <th scope="row">{client.customerName}</th>
              <th>{`${(client.monthlyTotals.productTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
              {client.monthlyTotals.productMonthTotal.map((total, monthIndex) => (
                <th key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
              ))}
            </tr>
            <tr>
              <td scope="row">VWPACK</td>
              <td>{`${(client.monthlyTotals.VWPACK.reduce((sum, value) => sum + value, 0)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
              {client.monthlyTotals.VWPACK.map((total, monthIndex) => (
                <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
              ))}
            </tr>
            <tr>
              <td scope="row">VWLAY</td>
              <td>{`${(client.monthlyTotals.VWLAY.reduce((sum, value) => sum + value, 0)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
              {client.monthlyTotals.VWLAY.map((total, monthIndex) => (
                <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
              ))}
            </tr>
            <tr>
              <td scope="row">VWGO</td>
              <td>{`${(client.monthlyTotals.VWGO.reduce((sum, value) => sum + value, 0)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
              {client.monthlyTotals.VWGO.map((total, monthIndex) => (
                <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
              ))}
            </tr>
            <tr className="thead-clients">
              <th scope="row">Ticket Medio</th>
              <th>{`${(client.monthlyTotals.productTotal / client.monthlyTotals.ticketsTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
              {client.monthlyTotals.productMonthTotal.map((productTotal, monthIndex) => {
                const ticketsTotal = client.monthlyTotals.ticketsMonthTotal[monthIndex];
                const averageTicket = ticketsTotal !== 0 ? `${(productTotal / ticketsTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}` : "N/A";

                return <td key={monthIndex}>{`${(averageTicket).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>;
              })}
            </tr>
            <tr className="thead-clients">
              <th scope="row">Clientes</th>
              <th>{client.monthlyTotals.ticketsTotal}</th>
              {client.monthlyTotals.ticketsMonthTotal.map((ticketsTotal, monthIndex) => {
                return <td key={monthIndex}>{`${(ticketsTotal).toLocaleString("es-ES")}`}</td>;
              })}
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    );
  }

  calculatePriceWithoutTaxAndComission(priceWithTax, taxRate, comissionRate) {
    // Convert tax rate from percentage to decimal
    const taxMultiplier = 1 + taxRate / 100;

    let comissionMultiplier = 1;

    comissionRate != 0 ? comissionMultiplier = comissionRate / 100 : comissionMultiplier = 1

    // Calculate price without tax and round to two decimal places
    const priceWithoutTaxAndComission = ((priceWithTax / taxMultiplier) * comissionMultiplier).toFixed(2);

    // Convert the string back to a number
    return parseFloat(priceWithoutTaxAndComission);
  }

  calculateNetCustomerTotals(customer) {
    const { arqueos } = this.props;

    // Initialize an object to store monthly totals
    const monthlyTotals = {
      VWPACK: Array(12).fill(0),
      VWGO: Array(12).fill(0),
      VWLAY: Array(12).fill(0),
      productMonthTotal: Array(12).fill(0),
      productTotal: 0,
      ticketsMonthTotal: Array(12).fill(0),
      ticketsTotal: 0,
      VWPACKTotal: 0,
      VWGOTotal: 0,
      VWLAYTotal: 0,
      ContractType: "",
      SpaceRental: 0
    };

    // Iterate through arqueos to find the ones belonging to the current customer
    arqueos.forEach(arqueo => {
      if (arqueo.Customer && arqueo.Customer.Fullname === customer && arqueo.Customer._id !== "627e3b97db8dc91eb9cc3ac2") {
        // Extract month from the timestamp
        const month = new Date(arqueo.TimeStamp).getUTCMonth();

        // Accumulate monthly totals
        monthlyTotals.VWPACK[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.VWPACK, arqueo.Tax, arqueo.ItemsComissions.VWPACK) || 0;
        monthlyTotals.VWGO[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.VWGO, arqueo.Tax, arqueo.ItemsComissions.VWGO) || 0;
        monthlyTotals.VWLAY[month] += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.VWLAY, arqueo.Tax, arqueo.ItemsComissions.VWLAY) || 0;
        monthlyTotals.productMonthTotal[month] += (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.VWPACK, arqueo.Tax, arqueo.ItemsComissions.VWPACK) || 0) + (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.VWGO, arqueo.Tax, arqueo.ItemsComissions.VWGO) || 0) + (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.VWLAY, arqueo.Tax, arqueo.ItemsComissions.VWLAY) || 0);
        monthlyTotals.productTotal += (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.VWPACK, arqueo.Tax, arqueo.ItemsComissions.VWPACK) || 0) + (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.VWGO, arqueo.Tax, arqueo.ItemsComissions.VWGO) || 0) + (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.VWLAY, arqueo.Tax, arqueo.ItemsComissions.VWLAY) || 0);
        monthlyTotals.ticketsMonthTotal[month] += arqueo.TotalTickets
        monthlyTotals.ticketsTotal += arqueo.TotalTickets
        monthlyTotals.VWPACKTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.VWPACK, arqueo.Tax, arqueo.ItemsComissions.VWPACK) || 0;
        monthlyTotals.VWGOTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.VWGO, arqueo.Tax, arqueo.ItemsComissions.VWGO) || 0;
        monthlyTotals.VWLAYTotal += this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.VWLAY, arqueo.Tax, arqueo.ItemsComissions.VWLAY) || 0;
        monthlyTotals.ContractType = arqueo.ContractType;
        monthlyTotals.SpaceRental = arqueo.SpaceRental;
      }
    });

    return {
      customerName: customer,
      monthlyTotals,
    };
  }

  createNetClientsArray() {
    const { arqueos } = this.props;

    if (!arqueos) {
      return (
        <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      );
    }

    const customers = Array.from(new Set(arqueos.map(arqueo => arqueo.Customer && arqueo.Customer.Fullname)));
    const clientsArray = [];

    customers.forEach(customer => {
      if (customer && customer !== "VACWAY WATERPROOF SL") {
        const customerTotals = this.calculateNetCustomerTotals(customer);
        clientsArray.push(customerTotals);
      }
    });

    // Sort clientsArray in descending order based on productTotal
    clientsArray.sort((a, b) => b.monthlyTotals.productTotal - a.monthlyTotals.productTotal);

    return clientsArray;
  }

  renderNetTableBody() {
    const { arqueos, loading, error } = this.props;

    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueos || loading) {
      return <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>;
    }

    const clientsArray = this.createNetClientsArray();

    const totalRow = {
      customerName: 'Todos',
      monthlyTotals: {
        productTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.productTotal, 0),
        ticketsTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.ticketsTotal, 0),
        VWPACKTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.VWPACKTotal, 0),
        VWLAYTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.VWLAYTotal, 0),
        VWGOTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.VWGOTotal, 0),
        productMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.productMonthTotal[i]), Array(12).fill(0)),
        ticketsMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.ticketsMonthTotal[i]), Array(12).fill(0)),
        VWPACKMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.VWPACK[i]), Array(12).fill(0)),
        VWLAYMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.VWLAY[i]), Array(12).fill(0)),
        VWGOMonthTotal: clientsArray.reduce((sums, client) => sums.map((value, i) => value + client.monthlyTotals.VWGO[i]), Array(12).fill(0)),
        SpaceRental: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.SpaceRental, 0),
      },
    };

    return (
      <tbody>
        <tr className="thead-totals">
          <th scope="row">Todos (Venta Neta)</th>
          <th>{`${(totalRow.monthlyTotals.productTotal - totalRow.monthlyTotals.SpaceRental).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
          {totalRow.monthlyTotals.productMonthTotal.map((total, monthIndex) => (
            <td key={monthIndex}>{monthIndex == 7 ? `${(total - totalRow.monthlyTotals.SpaceRental).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}` : `${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
          ))}
        </tr>
        <tr>
          <th scope="row">VWPACK</th>
          <th>{`${(totalRow.monthlyTotals.VWPACKTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
          {totalRow.monthlyTotals.VWPACKMonthTotal.map((total, monthIndex) => (
            <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
          ))}
        </tr>
        <tr>
          <th scope="row">VWLAY</th>
          <th>{`${(totalRow.monthlyTotals.VWLAYTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
          {totalRow.monthlyTotals.VWLAYMonthTotal.map((total, monthIndex) => (
            <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
          ))}
        </tr>
        <tr>
          <th scope="row">VWGO</th>
          <th>{`${(totalRow.monthlyTotals.VWGOTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
          {totalRow.monthlyTotals.VWGOMonthTotal.map((total, monthIndex) => (
            <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
          ))}
        </tr>
        <tr className="thead-clients">
          <th scope="row">Ticket Medio</th>
          <th>{`${(totalRow.monthlyTotals.productTotal / totalRow.monthlyTotals.ticketsTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
          {totalRow.monthlyTotals.productMonthTotal.map((productTotal, monthIndex) => {
            const ticketsTotal = totalRow.monthlyTotals.ticketsMonthTotal[monthIndex];
            const averageTicket = ticketsTotal !== 0 ? `${(productTotal / ticketsTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}` : "N/A";

            return <td key={monthIndex}>{`${(averageTicket).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>;
          })}
        </tr>
        <tr className="thead-clients">
          <th scope="row">Clientes</th>
          <th>{totalRow.monthlyTotals.ticketsTotal}</th>
          {totalRow.monthlyTotals.ticketsMonthTotal.map((ticketsTotal, monthIndex) => {
            return <td key={monthIndex}>{`${(ticketsTotal).toLocaleString("es-ES")}`}</td>;
          })}
        </tr>
        {clientsArray.map((client, index) => (
          <React.Fragment key={index}>
            <tr className="thead-dark">
              <th scope="row">{client.customerName}</th>
              <th>{`${(client.monthlyTotals.productTotal - client.monthlyTotals.SpaceRental).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
              {client.monthlyTotals.productMonthTotal.map((total, monthIndex) => (
                <th key={monthIndex}>{monthIndex == 7 ? `${(total - client.monthlyTotals.SpaceRental).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}` : `${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
              ))}
            </tr>
            <tr>
              <td scope="row">VWPACK</td>
              <td>{`${(client.monthlyTotals.VWPACK.reduce((sum, value) => sum + value, 0)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
              {client.monthlyTotals.VWPACK.map((total, monthIndex) => (
                <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
              ))}
            </tr>
            <tr>
              <td scope="row">VWLAY</td>
              <td>{`${(client.monthlyTotals.VWLAY.reduce((sum, value) => sum + value, 0)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
              {client.monthlyTotals.VWLAY.map((total, monthIndex) => (
                <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
              ))}
            </tr>
            <tr>
              <td scope="row">VWGO</td>
              <td>{`${(client.monthlyTotals.VWGO.reduce((sum, value) => sum + value, 0)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
              {client.monthlyTotals.VWGO.map((total, monthIndex) => (
                <td key={monthIndex}>{`${(total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>
              ))}
            </tr>
            <tr className="thead-clients">
              <th scope="row">Ticket Medio</th>
              <th>{`${(client.monthlyTotals.productTotal / client.monthlyTotals.ticketsTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</th>
              {client.monthlyTotals.productMonthTotal.map((productTotal, monthIndex) => {
                const ticketsTotal = client.monthlyTotals.ticketsMonthTotal[monthIndex];
                const averageTicket = ticketsTotal !== 0 ? `${(productTotal / ticketsTotal).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}` : "N/A";

                return <td key={monthIndex}>{`${(averageTicket).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</td>;
              })}
            </tr>
            <tr className="thead-clients">
              <th scope="row">Clientes</th>
              <th>{client.monthlyTotals.ticketsTotal}</th>
              {client.monthlyTotals.ticketsMonthTotal.map((ticketsTotal, monthIndex) => {
                return <td key={monthIndex}>{`${(ticketsTotal).toLocaleString("es-ES")}`}</td>;
              })}
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    );
  }

  handleTypeClick(type) {
    this.setState({ selectedType: type });
  }

  render() {
    const { selectedType } = this.state

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1 className="text-vw-dark">
              KPI DE VENTAS
            </h1>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-4 col-md-4">
          <Box title="Filtro General" icon="filter">
              <div className="row">
                <div className="col d-flex align-items-center justify-content-center">
                  <div className="btn-group mt-2 mb-2 p-2" role="group">
                    <button
                      type="button"
                      className={`btn btn-multi-line ${selectedType === 'Units' ? 'btn-primary' : 'btn-secondary'}`}
                      onClick={() => this.handleTypeClick('Units')}
                    >
                      Unidades (Temp mercado)
                    </button>
                    <button
                      type="button"
                      className={`btn btn-multi-line ${selectedType === 'Gross' ? 'btn-primary' : 'btn-secondary'}`}
                      onClick={() => this.handleTypeClick('Gross')}
                    >
                      € GMV (Temp mercado)
                    </button>
                    <button
                      type="button"
                      className={`btn btn-multi-line ${selectedType === 'Active' ? 'btn-primary' : 'btn-secondary'}`}
                      onClick={() => this.handleTypeClick('Active')}
                    >
                      € GMV (Activos Vacway)
                    </button>
                    <button
                      type="button"
                      className={`btn btn-multi-line ${selectedType === 'Net' ? 'btn-primary' : 'btn-secondary'}`}
                      onClick={() => this.handleTypeClick('Net')}
                    >
                      € Venta Neta
                    </button>
                  </div>
                </div>
              </div>
            </Box>
          </div>
          <div className="col-xs-12 col-sm-8 col-md-8">
            <Box title="Filtro Específico" icon="filter">
              <ArqueosSalesFilterForm />
            </Box>
          </div>
        </div>

        {/* Table to display arqueo information */}
        <div className="table-responsive">
          <table className="table table-hover table-clickable">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Cliente</th>
                <th scope="col">Total</th>
                <th scope="col">Enero</th>
                <th scope="col">Febrero</th>
                <th scope="col">Marzo</th>
                <th scope="col">Abril</th>
                <th scope="col">Mayo</th>
                <th scope="col">Junio</th>
                <th scope="col">Julio</th>
                <th scope="col">Agosto</th>
                <th scope="col">Septiembre</th>
                <th scope="col">Octubre</th>
                <th scope="col">Noviembre</th>
                <th scope="col">Diciembre</th>
              </tr>
            </thead>
            {this.state.selectedType === 'Units' && this.renderUnitsTableBody()}
            {this.state.selectedType === 'Gross' && this.renderGrossTableBody()}
            {this.state.selectedType === 'Active' && this.renderActiveTableBody()}
            {this.state.selectedType === 'Net' && this.renderNetTableBody()}
          </table>
        </div>
      </div >
    )
  }
}

const mapStateToProps = state => ({
  arqueos: state.arqueos.arqueos,
  error: state.arqueos.error,
  loading: state.arqueos.loading,
  auth: state.auth,
  form: state.form
})

const mapDispatchToProps = dispatch => ({
  fetchArqueos: bindActionCreators(fetchArqueos, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ArqueosSalesView)
