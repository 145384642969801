import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Link } from 'react-router-dom'
import { history } from '../store'

import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown
} from 'reactstrap'

import { authLogout } from '../actions/auth'

class AppHeader extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      showDragon: false,
    }

    this.toggle = this.toggle.bind(this);
    this.logout = this.logout.bind(this);
    this.showDragonGif = this.showDragonGif.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  logout() {
    this.props.authLogout()
    this.props.push('/login')
  }

  handleNavLinkClick() {
    this.setState({
      isOpen: false
    });
  }

  goTo(name, e) {
    const { role, type, fullname, id } = this.props

    this.handleNavLinkClick()

    { role === 'ADMIN' && history.push(`/user/${name}`) }

  }

  showDragonGif() {
    const { role, type, fullname, id } = this.props

    if (fullname === 'Daniel Souto') {
      this.setState(prevState => ({
        showDragon: !prevState.showDragon
      }));
    }

  }

  topFunction() {
    window.scrollTo(0, 0);
  }

  render() {
    const { role, type, fullname, id } = this.props

    return (
      <Navbar className="box-shadow bg-vw-dark" dark expand="md">
        <Container>
          <NavbarBrand tag={Link} to="/">
            <img className="logo" src="/images/logo.png" alt="Logo" />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {(role === 'CUSTOMER' || role === 'ADMIN') && type !== 'CASH' &&
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    PUNTOS DE VENTA
                  </DropdownToggle>
                  <DropdownMenu className="custom-submenu">
                    <DropdownItem tag={Link} to="/machines" onClick={() => this.handleNavLinkClick()}>
                      <i className="fas fa-store mr-1"></i>ONES
                    </DropdownItem>
                    {role === 'ADMIN' && type !== 'LIMITED' &&
                      <DropdownItem tag={Link} to="/minis" onClick={() => this.handleNavLinkClick()}>
                        <i className="fas fa-store mr-1"></i>MINIS
                      </DropdownItem>
                    }
                  </DropdownMenu>
                </UncontrolledDropdown>
              }
              {role === 'ADMIN' && type !== 'CASH' &&
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    OPERATIVA
                  </DropdownToggle>
                  <DropdownMenu className="custom-submenu">
                    {type !== 'LIMITED' &&
                      <DropdownItem tag={Link} to="/incidences" onClick={() => this.handleNavLinkClick()}>
                        <i className="fas fa-exclamation-triangle mr-1"></i>INCIDENCIAS
                      </DropdownItem>
                    }
                    {type !== 'LIMITED' &&
                      <DropdownItem tag={Link} to="/audit" onClick={() => this.handleNavLinkClick()}>
                        <i className="fas fa-database mr-1"></i>AUDIT
                      </DropdownItem>
                    }
                    <DropdownItem tag={Link} to="/modules" onClick={() => this.handleNavLinkClick()}>
                      <i className="fas fa-th mr-1"></i>MÓDULOS
                    </DropdownItem>
                    {type !== 'LIMITED' &&
                      <DropdownItem tag={Link} to="/tpvs" onClick={() => this.handleNavLinkClick()}>
                        <i className="fas fa-mobile mr-1"></i>TPVs
                      </DropdownItem>
                    }
                  </DropdownMenu>
                </UncontrolledDropdown>
              }
              {(role === 'CUSTOMER' || role === 'ADMIN') && type !== 'LIMITED' &&
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    VENTAS
                  </DropdownToggle>
                  <DropdownMenu >
                    <DropdownItem tag={Link} to="/sales" onClick={() => this.handleNavLinkClick()}>
                      <i className="fas fa-filter mr-1"></i>ANÁLISIS
                    </DropdownItem>
                    {role === 'ADMIN' &&
                      <DropdownItem tag={Link} to="/arqueos" onClick={() => this.handleNavLinkClick()}>
                        <i class="fas fa-chart-bar mr-1"></i>GRÁFICOS
                      </DropdownItem>
                    }
                    {role === 'ADMIN' &&
                      <DropdownItem tag={Link} to="/arqueossales" onClick={() => this.handleNavLinkClick()}>
                        <i class="fas fa-chart-pie mr-1"></i>KPI
                      </DropdownItem>
                    }
                    {role === 'ADMIN' && type !== 'CASH' &&
                      <DropdownItem tag={Link} to="/objectives" onClick={() => this.handleNavLinkClick()}>
                        <i class="fas fa-bullseye mr-1"></i>OBJETIVOS
                      </DropdownItem>
                    }
                    {role === 'ADMIN' && type !== 'CASH' &&
                      <DropdownItem tag={Link} to="/comissions" onClick={() => this.handleNavLinkClick()}>
                        <i class="fas fa-sack-dollar mr-1"></i>COMISIONES
                      </DropdownItem>
                    }
                  </DropdownMenu>
                </UncontrolledDropdown>
              }
              {/* {role === 'ADMIN' && type === 'GOD' &&
                <NavItem>
                  <NavLink tag={Link} to="/pin" onClick={() => this.handleNavLinkClick()}>RECUPERAR PIN</NavLink>
                </NavItem>
              } */}
              {role === 'ADMIN' && (type === 'HHRR' || type === 'GOD') &&
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    FICHAJES
                  </DropdownToggle>
                  <DropdownMenu className="custom-submenu">
                    <DropdownItem tag={Link} to="/shifts" onClick={() => this.handleNavLinkClick()}>
                      <i className="fas fa-clock mr-1"></i>JORNADA PROMOTOR
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/shiftsadmin" onClick={() => this.handleNavLinkClick()}>
                      <i className="fas fa-clock mr-1"></i>JORNADA TRABAJADOR
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              }
              {role === 'ADMIN' && type !== 'LIMITED' && type !== 'CASH' &&
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    ADMINISTRACIÓN
                  </DropdownToggle>
                  <DropdownMenu >
                    <DropdownItem tag={Link} to="/customers" onClick={() => this.handleNavLinkClick()}>
                      <i className="fas fa-crown mr-1"></i>CLIENTES
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/contracts" onClick={() => this.handleNavLinkClick()}>
                      <i className="fas fa-file-signature mr-1"></i>CONTRATOS
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/promoters" onClick={() => this.handleNavLinkClick()}>
                      <i className="fas fa-users mr-1"></i>PROMOTORES
                    </DropdownItem>
                    <div>
                      <DropdownItem divider />
                      <DropdownItem tag={Link} to="/users" onClick={() => this.handleNavLinkClick()}>
                        <i className="fas fa-user-secret mr-1"></i>TRABAJADORES
                      </DropdownItem>
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
              }
              <button className="btn btn-outline-vacway text-white" style={{ paddingRight: '20px', paddingLeft: '20px' }} onClick={e => this.goTo(fullname, e)}>
                {role === 'ADMIN' ? (<img className="user-icon mr-2" src={`https://vacway-images.s3.eu-central-1.amazonaws.com/${fullname}.png`} alt="User" />) : (<i className="fas fa-user mr-1"></i>)}
                {fullname}
              </button>
              <button className="btn btn-outline-light" onClick={this.logout}>
                <i className="fas fa-sign-out-alt mr-1"></i>
                SALIR
              </button>
              {this.state.showDragon && (
                <img className="dragon-gif" src="/images/7VE.gif" alt="Dragon GIF" />
              )}
            </Nav>
          </Collapse>
        </Container>
        <div className="topButton" onClick={this.topFunction}>^</div>
      </Navbar>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.auth.role,
  type: state.auth.type,
  fullname: state.auth.fullname,
})

const mapDispatchToProps = dispatch => ({
  authLogout: bindActionCreators(authLogout, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader)
